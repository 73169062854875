import ActionType from '../../../components/part/provider/ActionType'
import workflowServices from '../../../services/work-flow'
import { ACTION_TYPE_LIST, COMMUNICATION_TYPE_LIST } from '../../../misc/commons'
import draggable from 'vuedraggable'
import VueTagsInput from '@johmun/vue-tags-input'
import VueDropzone from 'vue2-dropzone'
import DelegateMailModel from '../WorkflowActions/DelegateMailModel'
import ErrorModal from '../../share/ErrorModal'
import store from '../../../store'
import { COMMUNICATION_TYPE } from '../../../misc/commons'
import noti from '../../../utils/noti_toast'
export default {
    props: ['communicationId', 'id', 'disabledDelegate','providerCommunicationId','communicationType', 'providerEventStatus', 'completedRecall'],
    components: {
        ActionType,
        draggable,
        VueTagsInput,
        VueDropzone,
        DelegateMailModel,
        ErrorModal
    },
    data() {
        return {
            item: {
                email: [],
                comment: null,
                subject: null,
                OrganizationId: null,
                DelegatId: 0,
                document: [],
                recalldocuments: [],
                actionsList: [],
            },
            communicationTypeForRecall: COMMUNICATION_TYPE.RECALL,
            supplierModalKey: 0,
            viewBy: null,
            delegateModalKey: 0,
            actionList: [],
            actionList_Details: [],
            selectedActionId: null,
            actions: [],
            customActions: [],
            originalCustomActions: [],
            beforeEditCustomActions: '',
            supportingDoc: [],
            description: '',
            actionType: null,
            optionsAction: ACTION_TYPE_LIST,
            communicationTypeList: COMMUNICATION_TYPE_LIST,
            item1: '',
            loading: false,
            isEnable: false,
            isCheckAll: '',
            isCheckSquare1: '',
            isCheckSquare: '',
            isCheckAll1: '',
            clearCheckAll: '',
            moreLess: '',
            isExpanded: false,
            isExpanded1: false,
            isDragging: false,
            delayedDragging: false,
            info: null,
            Listitems: [],
            docitems: [],
            filesUpload: [],
            tag: '',
            autocompleteItems: [],
            debounce: null,
            emailError: '',
            domainError: '',
            commentError: '',
            loadingInitial: true,
            producFieldMetaList: [],
            products: [],
            errorList: [],
            dropzoneOptions: {
                url: '#',
                acceptedFiles: '.xlsx,.xls,.csv',
                maxFiles: 1,
                maxFilesize: 20,
                previewsContainer: false,
                autoProcessQueue: false,
            },
            isFileSelect: false,
            file: null,
            formKey: 0,
            value: [],
            valueTag: [],
            errorModalKey: 0,

            actionListFields: [
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                    column_classes: 'customalign-15percent',
                    row_classes: 'customalign-15percent wordbreakall',
                },
                {
                    key: 'progress',
                    label: 'Progress',
                    class: 'text-center',
                    sortable: true,
                },
            ],
            actionListFields1: [
                {
                    key: 'description',
                    label: '',
                    sortable: false,
                },
                {
                    key: 'action_type',
                    label: '',
                    class: 'text-center',
                    sortable: false,
                },
            ],
            actionList_Details_Fields: [
                {
                    key: 'email',
                    label: 'Delegate Email',
                    sortable: true,
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true,
                },
            ],

            providerList: [],
            delegateList: [],
            delegateList_Details: [],
            selectedProviderId: null,
            selectedDelegateId: null,
            organizationid: null,
            clicked: '',
            isShow: false,
            type: 'DELEGATE_ACTIONS',

            delegateListFields: [
                {
                    key: 'delegate_name',
                    label: 'Delegate Email',
                    sortable: true,
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false,
                },
                {
                    key: 'completed',
                    label: 'Tasks Done',
                    class: 'text-center',
                    sortable: true,
                },
            ],
            delegateList_Details_Fields: [
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true,
                },
            ],
            DELEGATE_RECALL_STATUS: store.state.userType =='SUPPLIER'? [
                { value: null, text: 'Sent', color: '#ffc107' },
                { value: 'PENDING', text: 'Sent', color: '#ffc107' },
                { value: 'RESPONDED', text: 'Responded', color: '#28a745' },
                { value: 'DRAFT', text: 'Pending', color: '#ffc107' },
                { value: 'ERROR', text: 'Error', color: '#DC3545' },
            ] : [
                { value: 'PENDING', text: 'Sent', color: '#ffc107' },
                { value: 'RESPONDED', text: 'Responded', color: '#28a745' },
                { value: 'DRAFT', text: 'Draft', color: '#ffc107' },
                { value: 'ERROR', text: 'Error', color: '#DC3545' },
            ],
            DELEGATE_RECALL_RES_STATUS: store.state.userType =='SUPPLIER'? [
                { value: 'OPEN', text: 'Opened', color: '#ffc107' },
                { value: 'SEND', text: 'Sent', color: '#ffc107' },
                { value: 'DELIVERY', text: 'Delivered', color: '#ffc107' },
                { value: 'CLICK', text: 'Opened', color: '#28a745' },
                { value: 'DRAFT', text: 'Draft', color: '#ffc107' },
                { value: 'SEND', text: 'Pending', color: '#ffc107' },
            ] : [
                { value: 'OPEN', text: 'Opened', color: '#ffc107' },
                { value: 'SEND', text: 'Sent', color: '#ffc107' },
                { value: 'DELIVERY', text: 'Delivered', color: '#ffc107' },
                { value: 'CLICK', text: 'Click', color: '#ffc107' },
                { value: 'DRAFT', text: 'Draft', color: '#ffc107' },
                { value: null, text: 'Pending', color: '#ffc107' },
            ],
            orgType: store.state.userType,
            delegatemailModelKey: 0,
            tinyOptions: {
                height: 0,
                branding: false,
                statusbar: false,
                nonbreaking_force_tab: true,
                indentation: '30pt',
                content_style:
          'p {font-size: 16px;color: #666666;font-family: Helvetica,Arial,sans-serif;}',
                setup: function(ed) {
                    ed.on('keydown', function(evt) {
                        if (evt.keyCode == 9) {
                            ed.execCommand('mceInsertContent', false, '&emsp;&emsp;&emsp;')
                            evt.preventDefault()
                        }
                    })
                },
                menubar: '',
                plugins: ['lists  image paste help'],
                toolbar:
          'undo redo  | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
            },
        }
    },
    mounted() {
        this.viewBy = 'Delegate'
        let index = this.options.findIndex(x => x.value == 'MultipleChoice')
        if (this.orgType == 'PROVIDER') {
            if (index > -1) {
                this.options.pop()
            }
        }
    },

    watch: {
        viewBy(val) {
            if (val == 'Action') {
                this.GetRecallAction()
                this.viewBy = val
            } else if (val == 'Delegate') {
                this.GetRecallAction_DelegateListing()
                this.viewBy = val
            }
        },
    },
    computed: {
        filteredItems() {
            return this.autocompleteItems.filter((i) => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
            })
        },
    },
    created() {
        this.$emit('onReLoadCommunicationData')
        this.$emit('onReLoadHistoryData')
        workflowServices.getCommunicationSummaryById(this.communicationId,this.providerCommunicationId,this.communicationType).then((resp) => {
            this.item1 = resp.data.d
        })
        this.getEmailSubjectByType(this.type)
        this.getDelegates()
    },
    methods: {
        handleFiles() {
            this.onModalComplete(this.$refs.filesInput)
        },
        onCompleted() {
            if (this.viewBy == 'Action') {
                this.GetRecallAction()
            } else if (this.viewBy == 'Delegate') {
                this.GetRecallAction_DelegateListing()
            }
        },
        FetchEmailList() {
            this.GetRecallAction_DelegateListing()
        },
        getEmailSubjectByType(type){
            workflowServices.getEmail(type,this.communicationType).then(resp => {
                if (!resp.error) {
                    this.item.subject = resp.data.d.subject.replace('${TitleCaseCommunicationType}', this._findObj(this.communicationTypeList, this.communicationType))
                }
            })
        },
        CheckDuplicateAction() {
            let duplicateCheck = []
            let duplicateCheckNew = []
            this.actions.filter((x) => {
                duplicateCheck.push(x.description)
            })
            this.customActions.filter((x) => {
                duplicateCheck.push(x.description)
            })
            let isError = false
            for (let i = 0; i < duplicateCheck.length; ++i) {
                if(!duplicateCheckNew.includes(duplicateCheck[i]))
                {
                    duplicateCheckNew.push(duplicateCheck[i])
                }
                else
                {
                    isError =  true
                }
            }
            return !isError
        },
        removeAction(item){
            this.customActions = this.customActions.filter(x => x.description != item.description)
            let formData = new FormData()
            formData.append('id', this.communicationId)
            formData.append('communicationType', this.communicationType)
            if(this.customActions.length == 0)
            {
                formData.append('actions[' + 0 + '].actionType', 'action_type')
                debugger
                formData.append('actions[' + 0 + '].description', 'description')
                formData.append('actions[' + 0 + '].id', 0)
                formData.append('actions[' + 0 + '].is_internal_action', 'is_internal_action')
            }
            else
            {
                this.customActions.forEach((x, j) => {
                    formData.append('actions[' + j + '].actionType', x.action_type)
                    formData.append('actions[' + j + '].description', x.description)
                    formData.append('actions[' + j + '].id', x.id == null ? 0 : x.id)
                    formData.append('actions[' + j + '].is_internal_action', x.is_internal_action == undefined ? 0 :x.is_internal_action)
                })
            }
            workflowServices.addCustomListActions(formData).then(res => {
                if (res.error) {
                    this._showToast(res.error.message, { variant: 'warning' })
                }
            })
        },
        EditAction(item) {
            let isValid = this.CheckDuplicateAction()
            if(!isValid)
            {
                noti.error('Error', 'Duplicate values cannot be entered.')
            }
            else
            {
                let duplicateCheck = []
                this.actions.filter((x) => {
                    duplicateCheck.push(x.description)
                })
                this.originalCustomActions.filter((x) => {
                    if(x.description != item.description)
                    {
                        duplicateCheck.push(x.description)
                    }
                })
                if(duplicateCheck.includes(item.description))
                {
                    noti.error('Error', 'Duplicate values cannot be entered.')
                }
                else
                {
                    this.customActions = this.customActions.map((x) => {
                        x.IsEditable = false
                        return {
                            ...x,
                        }
                    })
                    this.beforeEditCustomActions = item.description
                    this.customActions = this.customActions.map((x, index) => {
                        if(x.description == item.description)
                        {
                            console.log('2')
                            x.IsEditable = true
                        }
                        return {
                            ...x,
                        }
                    })
                }
            }
        },
        SaveAction(item) {
            let duplicateCheck = []
            this.actions.filter((x) => {
                duplicateCheck.push(x.description)
            })
            this.originalCustomActions.filter((x) => {
                if(x.description != this.beforeEditCustomActions)
                {
                    duplicateCheck.push(x.description)
                }
            })
            if(duplicateCheck.includes(item.description))
            {
                noti.error('Error', 'Duplicate values cannot be entered.')
            }
            else
            {
                let formData = new FormData()
                formData.append('id', this.communicationId)
                formData.append('communicationType', this.communicationType)
                if(this.customActions.length == 0)
                {
                    formData.append('actions[' + 0 + '].actionType', 'action_type')
                    formData.append('actions[' + 0 + '].description', 'description')
                    formData.append('actions[' + 0 + '].id', 0)
                    formData.append('actions[' + 0 + '].is_internal_action', 'is_internal_action')
                }
                else
                {
                    this.customActions.forEach((x, j) => {
                        formData.append('actions[' + j + '].actionType', x.action_type)
                        formData.append('actions[' + j + '].description', x.description)
                        formData.append('actions[' + j + '].id', x.id == null ? 0 : x.id)
                        formData.append('actions[' + j + '].is_internal_action', x.is_internal_action == undefined ? 0 :x.is_internal_action)
                    })
                }
                workflowServices.addCustomListActions(formData).then(res => {
                    if (res.error) {
                        this._showToast(res.error.message, { variant: 'warning' })
                    }
                })
                this.customActions = this.customActions.map((x) => {
                    x.IsEditable = false
                    return {
                        ...x,
                    }
                })
            }
        },
        addAction() {
            if (this.orgType == 'PROVIDER') {
                let valid = true
                if(this.description == '' && this.actionType == null)
                {
                    let formData = new FormData()
                    formData.append('id', this.communicationId)
                    formData.append('communicationType', this.communicationType)
                    this.customActions.forEach((x, j) => {
                        formData.append('actions[' + j + '].actionType', x.action_type)
                        debugger
                        formData.append('actions[' + j + '].description', x.description)
                        formData.append('actions[' + j + '].id', x.id)
                        formData.append('actions[' + j + '].is_internal_action', x.is_internal_action)
                    })
                    workflowServices.addCustomListActions(formData).then(res => {
                        if (res.error) {
                            this._showToast(res.error.message, { variant: 'warning' })
                        }
                    })
                }
                else
                {
                    let descriptionobj = this.description
                    let actionTypeobj = this.actionType
                    this.$refs['observerAddAction'].validate().then(isValid => {
                        if (isValid) {
                            var action = this.actions.filter(x => x.description === descriptionobj)
                            var customActions = this.customActions.filter(x => x.description === descriptionobj)
                            if (action.length > 0 || customActions.length > 0) {
                                noti.error('Error', 'You have already selected the action.')
                            } else {
                                let Arr = []
                                if (actionTypeobj == 'MultipleChoice') {
                                    let DupCkList = []
                                    if (this.value1 != '' && this.value1 != null) {
                                        DupCkList.push(this.value1)
                                    }
                                    for (let index = 0; index < this.inputs.length; ++index) {
                                        if (DupCkList.includes(this.inputs[index].value)) {
                                            if (this.inputs[index].value == this.value1) {
                                                this.value1 = null
                                            }
                                            valid = false
                                            noti.error('Error', 'Duplicate values cannot be entered.')
                                            break
                                        }
                                        if (this.inputs[index].value != '') {
                                            DupCkList.push(this.inputs[index].value)
                                        }
                                    }
                                    if (!valid) {
                                        return ''
                                    }
                                    if (this.inputs.length > 1) {
                                        if (this.value1 != '' || this.value1 != null) {
                                            this.inputs.push({ value: this.value1 })
                                            this.value1 = null
                                        }
                                    }
                                    else {
                                        if (this.value1 != '' || this.value1 != null) {
                                            this.inputs.push({ value: this.value1 })
                                            this.value1 = null
                                        }
                                    }
                                    for (let index = 0; index < this.inputs.length; ++index) {
                                        if (this.inputs.length == 1 && this.inputs[index].value == '') {
                                            if (this.value1 == '' || this.value1 == null) {
                                                valid = false
                                                noti.error('Error', 'Please add at least one option.')
                                            }
                                            else {
                                                this.inputs.push({ value: this.value1 })
                                                this.value1 = null
                                            }
                                        }
                                        if (this.inputs[index].value != '' && this.inputs[index].value != null) {
                                            Arr.push(this.inputs[index])
                                        }
                                    }
                                }
                                if (actionTypeobj == 'MultipleChoice' && Arr.length == 0) {
                                    this.inputs = []
                                    this.inputs.push({ value: '' })
                                    if (valid) {
                                        valid = false
                                        noti.error('Error', 'Please add at least one option.')
                                    }
                                }
                                if (valid) {
                                    this.customActions.push({
                                        id: null,
                                        description: descriptionobj,
                                        action_type: actionTypeobj,
                                        isCustomizedContent: this.isCustomizedContent,
                                        order: this.nextOrder,
                                        isCustomActionType: (this.isCustom == true || this.communicationType == this.productDisruption) ? true : false,
                                        isMultiselect: this.isMultiselect,
                                        isAllowOnlyOne: this.isAllowOnlyOne,
                                        actionValues: actionTypeobj == 'MultipleChoice' ? Arr : this.inputs,
                                        IsEditable: false,
                                        IsDelete: true,
                                        IsCustomAdded: true,
                                        checked: true
                                    })
                                    this.Listitems.push({
                                        id: null,
                                        description: descriptionobj,
                                        action_type: actionTypeobj,
                                        isCustomizedContent: this.isCustomizedContent,
                                        order: this.nextOrder,
                                        isCustomActionType: (this.isCustom == true || this.communicationType == this.productDisruption) ? true : false,
                                        isMultiselect: this.isMultiselect,
                                        isAllowOnlyOne: this.isAllowOnlyOne,
                                        actionValues: actionTypeobj == 'MultipleChoice' ? Arr : this.inputs,
                                        IsEditable: false,
                                        IsDelete: true,
                                        IsCustomAdded: true,
                                        checked: true
                                    })
                                    let formData = new FormData()
                                    formData.append('id', this.communicationId)
                                    formData.append('communicationType', this.communicationType)
                                    this.customActions.forEach((x, j) => {
                                        formData.append('actions[' + j + '].actionType', x.action_type)
                                        formData.append('actions[' + j + '].description', x.description)
                                        formData.append('actions[' + j + '].id', x.id == null ? 0 : x.id)
                                        formData.append('actions[' + j + '].is_internal_action', x.is_internal_action == undefined ? 0 :x.is_internal_action)
                                    })
                                    workflowServices.addCustomListActions(formData).then(res => {
                                        if (res.error) {
                                            this._showToast(res.error.message, { variant: 'warning' })
                                        }
                                        else
                                        {
                                            workflowServices.getProviderActionDetail(this.communicationId, this.$store.state.orgId,this.providerCommunicationId,this.communicationType)
                                                .then((res) => {
                                                    let cusList = res.data.d.filter(x => x.isSelfAdded == true).map((x) => {
                                                        return {
                                                            ...x,
                                                        }
                                                    })
                                                    for(let i=0; i <= cusList.length -1; i++)
                                                    {
                                                        this.Listitems.forEach((x, j) => {
                                                            if(x.description == cusList[i].description)
                                                            {
                                                                x.id = cusList[i].id
                                                            }
                                                        })
                                                        for(let j=0; j <= this.customActions.length -1; j++)
                                                        {
                                                            if (this.customActions[j].description == cusList[i].description) {
                                                                this.customActions[j].id = cusList[i].id
                                                            }
                                                        }
                                                    }
                                                })
                                        }
                                    })
                                    debugger
                                    this.customActions = this.customActions.map((x) => {
                                        if(this.description == x.description)
                                        {
                                            x.checked = true
                                        }
                                        this.isCheckAll = true
                                        return {
                                            ...x,
                                        }
                                    })
                                }
                                requestAnimationFrame(() => {
                                    this.$refs.observerAddAction.reset()
                                })
                            }
                        }
                    })
                    this.description = ''
                    this.actionType = []
                }
            }
        },
        onModalComplete(event) {
            this.filesUpload = event
            var len = this.supportingDoc.length
            var FilesData = ''
            if (this.filesUpload.files == undefined) {
                FilesData = this.filesUpload[0].files
            } else {
                FilesData = this.filesUpload.files
            }
            for (let i = 0; i < FilesData.length; i++) {
                if (FilesData[i].size > 10 * 1024 * 1024) {
                    this._showToast(
                        'The maximum size of the documents to be uploaded should be 10MB.',
                        { variant: 'danger', title: 'Error' }
                    )
                } else {
                    this.supportingDoc.push({
                        extension: '.' + FilesData[i].name.split('.')[1],
                        is_thumbnail: false,
                        fileName: FilesData[i].name.split('.')[0],
                        note: '',
                        path: '',
                        recallId: '',
                        fileSize: FilesData[i].size,
                        type: FilesData[i].type,
                        id: 0,
                        Ischecked: true,
                        file: FilesData[i],
                        order: len + 1,
                    })
                    this.docitems.push({
                        extension: '.' + FilesData[i].name.split('.')[1],
                        is_thumbnail: false,
                        fileName: FilesData[i].name.split('.')[0],
                        note: '',
                        path: '',
                        recallId: '',
                        fileSize: FilesData[i].size,
                        type: FilesData[i].type,
                        id: 0,
                        Ischecked: true,
                        file: FilesData[i],
                        order: len + 1,
                    })
                    len++
                }
            }
            if (this.docitems.length == 0) {
                this.isCheckAll1 = false
                this.isCheckSquare1 = false
            } else if (this.docitems.length == this.supportingDoc.length) {
                this.isCheckAll1 = false
                this.isCheckSquare1 = true
            } else if (this.docitems.length > 0) {
                this.isCheckAll1 = true
                this.isCheckSquare1 = false
            }
        },
        addSupDoc() {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderInsert()
            })
        },

        onSelect(event) {
            this.emailError = ''
            if (event.email) {
                var exists = this.delegateList.filter((elem) => {
                    return elem.delegate_name == event.email
                })
                if (exists.length > 0) {
                    this.$refs.addDelegate.emailError =
            'Recall has already been sent to this delegate.'
                }
            }
        },
        showImportProductModal() {
            this.delegateModalKey += 1
            this.$nextTick(() => {
                this.$refs.addDelegate.forceRerender(
                    this.communicationId,
                    this.producFieldMetaList
                )
            })
        },
        rowDelegateNameClick(item) {
            this.selectedDelegateId = item.delegate_id
            this.GetRecallAction_DelegateListing_Detail()
        },
        ViewDelegateMailData(item) {
            if(this.providerEventStatus != 'COMPLETED')
            {
                this.delegatemailModel += 1
                this.$nextTick(() => {
                    this.$refs.delegatemailModel.forceRerenderView(
                        item,
                        this.communicationId,
                        'View',
                        this.providerCommunicationId,
                        this.communicationType
                    )
                })
            }
        },
        ViewDelegateErrorModal(item) {
            this.errorModalKey += 1
            this.$nextTick(() => {
                this.$refs.errorModal.forceRerenderView(
                    item
                )
            })
        },
        ResendDelegateMail() {
            this.delegatemailModel += 1

            this.$nextTick(() => {
                this.$refs.delegatemailModel.forceRerenderView(
                    null,
                    this.communicationId,
                    'SendMail',
                    this.providerCommunicationId == undefined ? this.$route.params.providerCommunicationId : this.providerCommunicationId,
                    this.communicationType
                )
            })
        },
        rowDelegateClick(item) {
            this.selectedDelegateId = item.delegate_id
            this.GetRecallAction_DelegateListing_Detail()
        },
        rowTableActionClick(item) {
            this.selectedActionId = item.action_id
            this.GetRecallAction_Detail()
        },
        rowClassProvider(item, type) {
            if (!item || type !== 'row') return
            if (item.delegate_id === this.selectedDelegateId) return 'table-primary'
        },
        rowClassAction(item, type) {
            if (!item || type !== 'row') return
            if (item.action_id === this.selectedActionId) return 'table-primary'
        },
        GetRecallAction() {
            workflowServices.getRecallAction_Async(this.communicationId,this.providerCommunicationId,this.communicationType).then((resp) => {
                if (!resp.error) {
                    this.actionList = resp.data.d
                    if (
                        this.actionList &&
            this.actionList[0] &&
            this.actionList[0].action_id
                    ) {
                        this.selectedActionId = this.actionList[0].action_id
                        this.GetRecallAction_Detail()
                    }
                }
            })
        },
        GetRecallAction_Detail() {
            if (this.selectedDelegateId != null)
                workflowServices
                    .GetRecallAction_Detail(
                        this.communicationId,
                        this.selectedDelegateId,
                        this.selectedActionId,
                        this.providerCommunicationId,this.communicationType
                    )
                    .then((resp) => {
                        if (!resp.error) {
                            this.actionList_Details = resp.data.d
                        }
                    })
        },
        GetRecallAction_DelegateListing() {
            workflowServices
                .getRecallAction_DelegateListing(this.communicationId,this.providerCommunicationId,this.communicationType)
                .then((resp) => {
                    if (!resp.error) {
                        this.delegateList = resp.data.d
                        if (
                            this.delegateList &&
              this.delegateList[0] &&
              this.delegateList[0].delegate_id
                        ) {
                            this.selectedDelegateId = this.delegateList[0].delegate_id
                            this.organizationid = this.delegateList[0].provider_id
                            this.GetRecallAction_DelegateListing_Detail()
                        }
                    }
                })
        },
        GetRecallAction_DelegateListing_Detail() {
            workflowServices
                .getRecallAction_DelegateListing_Detail(
                    this.communicationId,
                    this.selectedDelegateId,this.communicationType
                )
                .then((resp) => {
                    if (!resp.error) {
                        this.delegateList_Details = resp.data.d
                    }
                })
        },
        downloadDelegateStatusReport() {
            workflowServices
                .downloadProviderDelegateStausReport(this.communicationId, this.item1.communicationUID,this.providerCommunicationId,this.item1.recallsitename,this.communicationType,'xlsx')
                .then((resp) => {})
        },
        exportDelegateStatusReportCSV() {
            workflowServices
                .downloadProviderDelegateStausReport(this.communicationId, this.item1.communicationUID,this.providerCommunicationId,this.item1.recallsitename,this.communicationType,'csv')
                .then((resp) => {})
        },
        getInternalAction() {
            workflowServices
                .getProviderActionDetail(this.communicationId, this.$store.state.orgId,this.providerCommunicationId,this.communicationType)
                .then((res) => {
                    this.actionData = res.data.d
                    this.actions = res.data.d.filter(x => x.isSelfAdded == false).map((x, index) => {
                        return {
                            ...x,
                            order: index + 1,
                        }
                    })
                    this.customActions = res.data.d.filter(x => x.isSelfAdded == true).map((x, index) => {
                        x.order= index + 1
                        x.IsCustomAdded= true
                        x.checked= false
                        return {
                            ...x,
                        }
                    })
                    this.originalCustomActions = this.customActions
                })
        },
        getSupportingDoc() {
            workflowServices.getSupportigDoc(this.communicationId,this.communicationType).then((res) => {
                this.supportingData = res.data.d
                this.supportingDoc = res.data.d.map((x, index) => {
                    return {
                        ...x,
                        Ischecked: false,
                        order: index + 1,
                    }
                })
            })
        },
        getDelegates() {
            workflowServices
                .getDelegateEmailList(this.$store.state.orgId, true,this.communicationType)
                .then((resp) => {
                    if (!resp.error) {
                        resp.data.d.forEach((val) => {
                            this.autocompleteItems.push({
                                text: val.email,
                                is_dl: val.is_dl,
                            })
                        })
                    }
                })
        },
        createDelegate() {
            //Add for domain validation
            if (this.domainError == 'domainError') {
                return this._showToast('The email domain is not valid.', {
                    title: 'Error',
                    variant: 'danger',
                })
            }
            this.DelegatId = 1
            //Add duplicate email validation
            if (this.value.length > 1) {
                for (var i = 0; i < this.value.length; i++) {
                    for (var k = i + 1; k < this.value.length; k++) {
                        if (this.value[i].email.trim() === this.value[k].email.trim()) {
                            return (this.emailError = 'The email has been already used.')
                        }
                    }
                }
            }
            //end
            var ListAction = this.Listitems
            var Docitems = this.docitems
            if (this.value.length == 0) {
                this.emailError = 'Email is required'
            } else {
                this.emailError = ''
            }
            let index1 = 0
            let Lenth = 0
            Docitems.forEach((x, k) => {
                if (x.recallId != '') {
                    Lenth = Lenth + x.fileSize
                    index1++
                } else {
                    Lenth = Lenth + x.file.size
                }
            })
            if (Lenth > 10 * 1024 * 1024) {
                this.docitems = []
                this.isCheckAll1 = false
                this.isCheckSquare1 = false
                this.supportingDoc.forEach((x, k) => {
                    x.Ischecked = false
                })
                this._showToast(
                    'The maximum size of the documents to be uploaded should be 10MB.',
                    { variant: 'danger', title: 'Error' }
                )
            } else {
                let formData = new FormData()
                for (let k = 0; k < this.value.length; k++) {
                    if (this.value[k].is_dl)
                        formData.append('distributionlist', this.value[k].email)
                    else formData.append('emaillist', this.value[k].email)
                }
                formData.append('comment', this.item.comment)
                formData.append('subject', this.item.subject)
                ListAction.forEach((x, j) => {
                    formData.append('actions[' + j + '].actiontype', x.action_type)
                    formData.append('actions[' + j + '].description', x.description)
                    formData.append('actions[' + j + '].actionid', x.id)
                    formData.append(
                        'actions[' + j + '].isinternalaction',
                        x.is_internal_action == null || x.is_internal_action == undefined ? 0 : x.is_internal_action
                    )
                })
                let index = 0
                Docitems.forEach((x, k) => {
                    if (x.recallId != '') {
                        formData.append(
                            'recalldocuments[' + index + '].extension',
                            x.extension
                        )
                        formData.append(
                            'recalldocuments[' + index + '].documentid',
                            x.documentId
                        )
                        formData.append(
                            'recalldocuments[' + index + '].filename',
                            x.fileName
                        )
                        formData.append(
                            'recalldocuments[' + index + '].communicationId',
                            x.communicationId == '' ? null : x.communicationId
                        )
                        formData.append(
                            'recalldocuments[' + index + '].filesize',
                            x.fileSize
                        )
                        formData.append(
                            'recalldocuments[' + index + '].mimetype',
                            x.mimeType
                        )
                        formData.append('recalldocuments[' + index + '].s3path', x.s3Path)
                        formData.append(
                            'recalldocuments[' + index + '].isthumbnail',
                            x.isThumbnail
                        )
                        formData.append('recalldocuments[' + index + '].datacontent', '')
                        index++
                    } else {
                        formData.append('document', x.file)
                    }
                })
                workflowServices
                    .submitDelegate(this.communicationId, this.DelegatId, formData,this.providerCommunicationId,this.communicationType)
                    .then((resp) => {
                        if (!resp.error) {
                            this.valueTag = []
                            this.Listitems = []
                            this.actions = []
                            this.docitems = []
                            this.supportingDoc = []
                            this.isCheckAll = false
                            this.isCheckSquare = false
                            this.isCheckAll1 = false
                            this.isCheckSquare1 = false
                            this.clearCheckAll = false
                            this.item.comment = null
                            this.getEmailSubjectByType(this.type)
                            this.isExpanded = false
                            this.isExpanded1 = false
                            this.delegateclick()
                            this.getInternalAction()
                            this._showToast('Email sent successfully!')
                            this.$emit('onCompleted')
                        }
                        else{
                            if(resp.data.c=='err_valid_emailcount')
                            {
                                this._showToast('E-mails are not sent to expected all users', {
                                    variant: 'error',
                                })
                            }
                        }
                    })
            }
        },
        checkDomainValidation(tag) {
            this.domainError = ''
            workflowServices
                .getDomain(tag.email, this.$store.state.orgId,this.communicationType)
                .then((resp) => {
                    if (resp.error) {
                        this.domainError = 'domainError'
                        this.value.pop(tag)
                        return false
                    } else {
                        return true
                    }
                })
        },
        checkEmailValidation(tag) {
            if (!tag) {
                this.emailError = 'Email is required'
            } else {
                this.emailError = ''
            }
            var currentTag = tag
            if (currentTag) {
                this.$emit('onSelect', currentTag)
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                var b = re.test(String(tag.email.trim()).toLowerCase())
                if (!b) {
                    this._showToast('Please enter valid email address', {
                        variant: 'warning',
                    })
                }
            }
        },

        forceRerender(recallId, producFieldMetaList) {
            this.loadingInitial = true
            this.resetData()
            this.producFieldMetaList = producFieldMetaList
            this.$refs.modal.show()
            this.loadingInitial = false
        },

        resetData() {
            this.file = null
            this.isFileSelect = false
            this.producFieldMetaList = []
            this.products = []
            this.errorList = []
        },

        onClose() {
            this.$emit('onClose')
        },

        save() {
            this.createDelegate()
        },
        delegateclick() {
            this.value = []
            this.Listitems = []
            this.getInternalAction()
            this.getSupportingDoc()
            this.getEmailSubjectByType(this.type)
            this.clicked = 'true'
            this.isShow = false
            this.isCheckAll = false
            this.isCheckSquare = false
            this.isCheckAll1 = false
            this.clearCheckAll = false
        },
        addCheck(val) {
            if (val.length > 0) {
                this.value = []
                val.forEach((x) => {
                    if (x.is_dl == true) {
                        const tag = {
                            email: x.text,
                            is_dl: x.is_dl,
                        }
                        this.value.push(tag)
                    } else {
                        const tag = {
                            email: x.text,
                            is_dl: false,
                        }
                        this.checkEmailValidation(tag)
                        if (!this.emailError) {
                            this.value.push(tag)
                            this.checkDomainValidation(tag)
                        }
                    }
                })
                if (this.value.length > 1) {
                    var valueOriginal = this.value.length
                    var valcurrent = val.length - 1
                    const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
                    var b = re.test(String(val[valcurrent].text.trim()).toLowerCase())
                    if (b) {
                        var i = 0
                        for (i = 0; i < valueOriginal - 1; i++) {
                            if (this.value[i].email.trim() === val[valcurrent].text.trim()) {
                                this.emailError = 'The email has been already used.'
                            }
                        }
                    }
                }
            } else {
                this.value = []
                this.domainError = ''
            }
        },
        checkboxChange(item, event) {
            if (event.target.checked) {
                this.Listitems.push(item)
            } else {
                this.Listitems.forEach((item1) => {
                    if (item1.description == item.description) {
                        this.Listitems.splice(this.Listitems.indexOf(item1), 1)
                    }
                })
            }
            if (this.Listitems.length == 0) {
                this.isCheckAll = false
                this.isCheckSquare = false
            } else if (this.Listitems.length == this.actions.length) {
                this.isCheckAll = false
                this.isCheckSquare = true
            } else if (this.Listitems.length > 0) {
                this.isCheckAll = true
                this.isCheckSquare = false
            }
        },
        supportingChange(item, event) {
            if (event.target.checked) {
                this.docitems.push(item)
            } else {
                this.docitems.forEach((item1) => {
                    if (item1.order == item.order) {
                        this.docitems.splice(this.docitems.indexOf(item1), 1)
                    }
                })
            }
            if (this.docitems.length == 0) {
                this.isCheckAll1 = false
                this.isCheckSquare1 = false
            } else if (this.docitems.length == this.supportingDoc.length) {
                this.isCheckAll1 = false
                this.isCheckSquare1 = true
            } else if (this.docitems.length > 0) {
                this.isCheckAll1 = true
                this.isCheckSquare1 = false
            }
        },
        expandMoreLess() {
            if (this.isExpanded == false) this.moreLess = 'See Less Information'
            else this.moreLess = 'See More Information'
        },
    },
}
